.Discover {
  .QuoteTitle .MainTitle {
    text-transform: uppercase;
    white-space: pre-wrap;

    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 2.5rem;
    }
  }

  .QuoteRow {
    margin-top: 2rem !important;
    margin-bottom: 0!important;
    justify-content: center;

    .Figure {
      .Img {
        max-height: 250px;
      }
    }

    .QuoteContainer {
      padding-left: 4rem;
    }
  }

  .DiscoverKpiContainer {
    max-width: 600px;
    margin: auto;

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .Member {
    .Figure {
      .Img {
        max-height: 200px!important;
      }
    }
  }

  .PartnerSection {
    padding-bottom: 15rem;

    .Figure {
      .Img {
        min-height: 150px;
        max-height: 150px;
      }
    }
  }

  .Supervisory-Board,
  .Board {
    .Card {
      .card-title {
        font-size: $font-size-md;
      }

      .card-body {
        padding: 0;
      }

      .card-text {
        font-size: $font-size-base;
      }

      .Row > [class*="col"] {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .Member {
      .Figure {
        .Img {
          max-height: 160px!important;
        }
      }
    }
  }

  .Supervisory-Board {
    padding-bottom: 50px;
  }
}
