.YoutubeVideoContainer {
  .youtube_player {
    height: 100%;

    iframe {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
