// DEDICATED STYLES
// --------------------------------------------------

// .Header
.Header {
  background: $body-bg;
  height: $header-height;

  .Navbar, .Container, .NavbarMenu, .Nav, .nav-item, .nav-link {
    height: 100%;
  }
  .Navbar {
    padding: 0;
  }
  .NavbarMenu {
    flex: 1 1 auto;
    justify-content: flex-end;

    .nav-item {
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        padding: 0 1rem;

        .Badge {
          font-size: $font-size-sm;
          text-transform: none;
        }
      }
    }
    .MenuNav {
      flex: 1 1 auto;
      justify-content: space-around;
      align-items: stretch;
      margin-right: $spacer;

      @include media-breakpoint-up(xl) {
        margin-right: $spacer-section;
      }
      .nav-item {
        flex: 0 0 auto;

        .nav-link {
          position: relative;

          &:after {
              content: "";
              display: block;
              position: absolute;
              left: 50%;
              right: 50%;
              bottom: 0;
              height: 0.4rem;
              border-radius: 1rem;
              background: currentColor;
              transition: $transition-base;
              opacity: 0;
          }
          &.active {
              &:after {
                opacity: 1;
                left: 0;
                right: 0;
              }
              .Text {
                  opacity: 1;
              }
          }
        }
      }
    }
  }
}
