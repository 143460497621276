.Pagination {
  white-space: nowrap;
  display: flex;
  justify-content: center;

  .PaginationItem {
    user-select: none;
    cursor: pointer;
    font-size: 1.5rem;
    align-self: center;

    &.selected {
      text-decoration: underline;
    }
  }
}