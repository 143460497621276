.PropertyItem {
  display: flex;
  padding: 1rem 2rem 1rem 1rem;
  cursor: pointer;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    padding: 1rem 1rem;
  }

  &.selected {
    background-color: lighten($gray-200, 2);
  }

  .Figure {
    width: 140px;
    height: 140px;
    margin-right: 1.5em;
    border-radius: $border-radius-md;

    @include media-breakpoint-down(xs) {
      width: 100%;
      height: 200px;
      justify-content: center;
      margin-right: 0;
    }
  }

  .content {
    width: 100%;

    @include media-breakpoint-down(xs) {
      padding-top: 1.5rem;
    }
    .city {
      margin-top: .5rem;
      font-size: 1.8rem;
    }

    .details {
      color: $dark-grey;
      font-size: 1.5rem;
    }

    .price {
      font-weight: 1000;
    }
  }
}