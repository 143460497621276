// DEDICATED STYLES
// --------------------------------------------------

// .Tag
.Tag {
  min-width: 12rem;
    font-weight: $font-weight-bold;
    justify-content: center;
    white-space: nowrap;

  &.TagLabel {
    font-size: 0.9rem;
  }
}

