.article-detail {
  .podcast {
    width: 100%;
  }

  .description {
    img {
      max-width: 100%;
    }
  }
}
