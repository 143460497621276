.EditorialView {
  position: relative;

  #EditorialBreadcrumb {
    position: sticky;
    top: 60px;
    padding: 36px 0 30px;
    background-color: $white;
    z-index: 2;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      position: inherit;
    }
  }

  #EditorialMenu {
    position: sticky;
    top: 150px;
    height: fit-content;
    min-width: 350px;
    max-width: 350px;


    @include media-breakpoint-down(sm) {
      min-width: unset;
      max-width: unset;
      position: inherit;
    }

    .Nav .nav-item .nav-link {
      position: relative;
      color: $black;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        color: $primary;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -$spacer-base;
        bottom: 0;
        width: 0.4rem;
        border-radius: 1rem;
        background: currentColor;
        transition: $transition-base;
        opacity: 0;
      }

      &.active {
        color: $primary;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  #EditorialContent {
    overflow: hidden;

    img {
      max-width: 100%;
    }

    li::marker {
      color: $primary;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
    }
  }
}
