// DEDICATED STYLES
// --------------------------------------------------

// .ListLive
.ListLive {
    font-size: $font-size-md;

    & > li {
        clear: both;

        &:not(:first-child) {
            margin-top: $spacer-base; 
        }
    }
    .Badge {
        float: left;
        margin-right: $spacer-base;
    }
}
