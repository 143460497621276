.react-autosuggest__suggestions-container  {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.react-autosuggest__container  {
  width: 100%;
}

.react-autosuggest__input  {
  margin-bottom: 0;
}

.city-suggestion {
  cursor: pointer;
}
