.Quote {
  color: $primary;
  font-weight: bold;
  position: absolute;

  &.Quote-md {
    font-size: 12rem;
    margin-top: -8rem;
    margin-left: -4rem;
  }

  &.Quote-xl {
    font-size: 24rem;
    margin-top: -17rem;
    margin-left: -7rem;
  }

  &.lg-margin {
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }

  &.md-margin {
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}
