.FaqView {
  .Container {
    & > input {
      margin-top: 2rem;
    }

    .Questions {
      margin: 5rem 0 3rem;

      .description {
        pre {
          white-space: break-spaces;
        }
      }
    }
  }
}