// DEDICATED STYLES
// --------------------------------------------------

// .Footer
.Footer {
  position: relative;
  //flex: 0 0 auto;
}

// .FooterMain
.FooterMain {
  .h4 {
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    min-height: $font-size-sm * $line-height-base * 3;
    margin: 0;
  }
  .nav-link {
    font-size: $font-size-sm;
    opacity: $alpha-base;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}

// .FooterLogo
.FooterLogo {
  @include media-breakpoint-up(md) {
    margin-right: $grid-gutter-width/2;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-left: -50vw;
  margin-top: -$spacer-section !important;
  margin-bottom: -$spacer-section !important;;
  padding-top: $spacer-section;
  padding-bottom: $spacer-section;
  padding-right: $grid-gutter-width;

  background: $body-bg;
  color: $body-color;

  .Logo {
    display: block;
    width: auto;
    height: 4rem;
    margin-bottom: $grid-gutter-width;
  }
}

// .FooterNav
.FooterNav {
  @include media-breakpoint-up(md) {
    margin-left: $grid-gutter-width/2 !important;
  }
  & > .Nav {
    @include make-row();

    & > .nav-item {
      @include make-col-ready();
      margin-bottom: $grid-gutter-width;
  
      @include media-breakpoint-down(xs){
        flex: 0 0 100%;
      }
      @include media-breakpoint-between(sm,md) {
        flex: 0 0 50%;
      }
      @include media-breakpoint-up(lg){
        flex: 0 0 25%;
      }
  
      display: flex;
      flex-direction: column;
  
      & > .Nav {
        flex: 1 0 auto;
        justify-content: flex-start;
        margin-bottom: 0;
  
        .nav-link {
          padding: 0.2rem 0;

          &:hover {
            opacity: 1;
          }
        }
        .flex-grow {
          flex: 1 0 auto;
           display: flex;
           align-items: flex-end;
        }
      }
    }
  }
}

// .FooterCopyright
.FooterCopyright {
  opacity: $alpha-base;
  font-size: 1.2rem;
}
