.map.leaflet-container {
  border-radius: 1rem;

  &.Map-sm {
    min-height: 260px;
  }

  &.Map-md {
    min-height: 380px;
  }

  &.Map-lg {
    min-height: 500px;
  }

  &.Map-xl {
    min-height: 720px;
  }

  .leaflet-top {
    &.leaflet-left {
      top: unset;
      left: unset;
      bottom: 10px;
      right: 20px;
    }
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    border: none;

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      border-radius: 50%;
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 36px;
      margin-bottom: 10px;
      text-indent: 0;
      border-bottom: none;
    }
  }

}

.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

.mycluster {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #000;
  border: 4px solid #8e8e8e;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  margin-top: -20px;
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
