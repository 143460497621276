// DEDICATED STYLES
// --------------------------------------------------

// .Img
.Img {
    max-width: 100%;

    &--housing {
        max-width: 640px;
    }
}