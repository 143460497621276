.ComplexCardAddress {
  .Container {
    & > h2, h3 {
      color: #fff;
    }
    & > p {
      color:  #fff;
    }
  }
}
.ComplexDescription {
  .Container {
    & > p {
      text-align: center;
    }
  }
}
