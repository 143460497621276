.propertiesMapView {
  .Container {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 160px);

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    .search-container {
      .Badge {
        font-size: 1.2rem;
      }

      .results {
        margin-top: $spacer-section;

        .header {
          font-size: 1.5rem;

          .h5 {
            font-size: 2rem;
          }

          @include media-breakpoint-down(md) {
            flex-direction: column;
            text-align: center;
          }
        }

        .result-list {
          height: calc(100vh - 265px);
          overflow: auto;

          @include media-breakpoint-down(sm) {
            height: unset;
            max-height: 60vh;
          }

          // Scrollbar hide
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .separator {
            height: 2px;
            background-color: $gray-300;
            margin: .5rem 1rem .5rem 170px;

            @include media-breakpoint-down(xs) {
              margin-left: 1rem;
            }
          }
        }
      }
    }

    .map-container {
      display: flex;
      flex-direction: column;

      .Card {
        flex-grow: 1;
        max-height: 80vh;

        .Map {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .map {
            flex-grow: 1;
          }
        }
      }
    }
  }
}