// DEDICATED STYLES
// --------------------------------------------------

// .CardOffer
.CardOffer {
  .city {
    color: $text-muted;
    font-size: $h3-font-size;
    font-weight: $font-weight-light;
  }
  .dateStart {
    color: $text-muted;
    font-size: 1.4rem;
  }
}
