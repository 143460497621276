.ticket-stepper {
  width: 190px;
  min-width: 190px;
  min-height: 480px;
  border-right: 1px solid $gray-400;

  @include media-breakpoint-down(lg) {
    min-height: unset;
    width: 90%;
    border-right: unset;
    border-top: 1px solid $gray-400;
    display: flex;
    justify-content: space-between;
    height: 30px;
  }
}

.ticket-stepper-mobile {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
    margin-bottom: 2rem;
    .title {
      font-size: 1.5rem;
      font-weight: 1000;
    }
    .subtitle {
      font-size: 1.2rem;
    }
  }
}

.ticket-stepper-content {
  flex-grow: 1;
  padding: 0 2rem;

  @include media-breakpoint-down(lg) {
    padding: 0;

    .container {
      padding: 0;
    }
  }
}