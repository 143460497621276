// DEDICATED STYLES
// --------------------------------------------------

// .List
.List {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    

    &.list-inline {
        list-style: none;
        padding: 0;
        flex-direction: row;

        @include make-row-size($gutter-ratio-sm);
  
      & > li {
        @include make-gutters-size-x($gutter-ratio-sm);
      }
    }
}