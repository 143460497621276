// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_forms.scss";
@import "~bootstrap/scss/_custom-forms.scss";
@import "~bootstrap/scss/_list-group.scss";


// DEDICATED STYLES
// --------------------------------------------------

// .Form
.Form {
  .legend {
    font-weight: normal;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  .card-footer {
    .form-group {
      flex-grow: 1;
    }
  }
}

// .Label
.Label {
  .Button {
    vertical-align: baseline;
  }
}

// .form-control
.form-control {
  &:not(.NoBorder) {
    //border-color: transparent;
    box-shadow: $input-box-shadow;
  }
}

// Radio & Checkbox
.form-check {
  padding-left: 0;
}
input[type="checkbox"], input[type="radio"] {
  &:not(.custom-control-input) {
    position: static;
    margin: 0 0.2rem 0 0;
  }
}

//Input asterix
.required{
  span{
      color: $primary;
    }
}

// .FormFooter
.FormFooter {
  margin: $grid-gutter-width 0;

  .Button {
    max-width: 40%;
    min-width: 14rem;

    &.btn-lg {
      min-width: 16rem;
    }
    &.btn-sm {
      min-width: 12rem;
    }
    @include media-breakpoint-up(md) {
      & + .Button {
        margin-left: 2rem;
      }
    }
    @include media-breakpoint-down(sm) {
      max-width: none;
      margin-top: 1rem;
    }
  }
}

// .form-group
.form-group {
  margin-bottom: $spacer-base;
  position: relative;

  &.align-label {
    padding-top: 2.2rem;
  }

  @at-root {
    .card-body > &,
    .Row.grid [class*="col"] > &,
    .Row.grid [class*="col"] > form > & {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-group, .Input {
    margin: 0;
  }
  
  &.row{
    @include make-row-size($gutter-ratio-sm);
    align-items: center;

    & > [class*="col"] {
      position: static;
      @include make-gutters-size-x($gutter-ratio-sm);
    }

    .col-form-label{
      padding: 0;

      &.col-auto{
        & + [class*="col-"]{
          flex: 1;
        }
      }
      label {
        margin: 0;
      }
    }
  }

  &.d-flex {
    .Figure {
      margin-right: $spacer-base;
      margin-bottom: 0 !important;
    }
  }

  // .PopoverTrigger
  .PopoverTrigger {
    position: absolute;
    left: 100%;
    margin-left: 0.2rem;
  }
}

.create-account-modale {
  .modal-title {
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 2rem;
    width: 100%;
  }
  .modal-body {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}

//Custom a styling for forgotten password
.forgot-pass {
  color: $primary;
  text-decoration: none;
  background-color: transparent;
  
  :hover{
    color: $tertiary;
    text-decoration: underline;
  }
}

// .react-autosuggest
.react-autosuggest__input {
  @extend .form-control;
}
.react-autosuggest__suggestions-list{
  @extend .list-group;
}
.react-autosuggest__suggestion {
  @extend .list-group-item;
}

//Offer Application form
.submit-application{
  min-height: 42px;
  font-size: 16px;
  min-width: 100%;
} 