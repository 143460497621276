// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/grid";

// DEDICATED STYLES
// --------------------------------------------------

// .Container
.Container {
  @include make-container-padding();
}

// .Row
.Row {
  flex: 1 1 100%;
  @include make-row-size();
  
  & > [class*="col"] {
    @include make-gutters-size-x();
  }
  
  &.grid {
    @include make-grid-size();
    align-items: stretch;

    & > [class*="col"] {
      @include make-gutters-size-y();
      display: flex;
      flex-direction: column;

      & > * {
        flex-grow: 1;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.flex-column,
    &.flex-column-reverse {
      & > [class*="col-"] {
        max-width: none !important;
      }
      & > .col-auto {
        width: 100%;
      }
    }
  }
  &.flex-nowrap {
    & > [class*="col"] {
      flex-grow: 1;
      flex-shrink: 1;
    }
    &.grid {
      & > [class*="col"] {
        display: flex;
        flex-direction: column;

        & > * {
          flex-grow: 1;
        }
      }
      &.flex-column,
      &.flex-column-reverse {
        & > [class*="col-"] {
          flex-direction: row;
        }
      }
    }
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-space-around {
    justify-content: space-around;
  }
  &.flex-fit {
    & > [class*="col-"] {
      flex-grow: 1 !important;
      flex-shrink: 1 !important;
    }
  }
  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-stretch {
    align-items: stretch;
  }
  
  // .gutters-sm
  &.gutters-none {
    @include make-row-size(0);

    & > [class*="col"] {
      @include make-gutters-size-x(0);
    }

    &.grid {
      @include make-grid-size(0);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y(0);
      }
    }
  }
  
  // .gutters-xs
  &.gutters-xs {
    @include make-row-size($gutter-ratio-xs);

    & > [class*="col"] {
      @include make-gutters-size-x($gutter-ratio-xs);
    }

    &.grid {
      @include make-grid-size($gutter-ratio-xs);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y($gutter-ratio-xs);
      }
    }
  }
  
  // .gutters-sm
  &.gutters-sm {
    @include make-row-size($gutter-ratio-sm);

    & > [class*="col"] {
      @include make-gutters-size-x($gutter-ratio-sm);
    }

    &.grid {
      @include make-grid-size($gutter-ratio-sm);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y($gutter-ratio-sm);
      }
    }
  }
  
  // .gutters-md
  &.gutters-md {
    @include make-row-size($gutter-ratio-md);

    & > [class*="col"] {
      @include make-gutters-size-x($gutter-ratio-md);
    }

    &.grid {
      @include make-grid-size($gutter-ratio-md);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y($gutter-ratio-md);
      }
    }
  }
  
  // .gutters-lg
  &.gutters-lg {
    @include make-row-size($gutter-ratio-lg);

    & > [class*="col"] {
      @include make-gutters-size-x($gutter-ratio-lg);
    }

    &.grid {
      @include make-grid-size($gutter-ratio-lg);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y($gutter-ratio-lg);
      }
    }
  }
  
  // .gutters-xl
  &.gutters-xl {
    @include make-row-size($gutter-ratio-xl);

    & > [class*="col"] {
      @include make-gutters-size-x($gutter-ratio-xl);
    }

    &.grid {
      @include make-grid-size($gutter-ratio-xl);
      align-items: stretch;

      & > [class*="col"] {
        @include make-gutters-size-y($gutter-ratio-xl);
      }
    }
  }
}

// .col-fit
.col-fit {
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
}