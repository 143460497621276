.ticket {
  margin: 1rem 0;
  //overflow: hidden;
  text-overflow: ellipsis;
  list-style: disc!important;

  .status {
    margin-left: 1rem;
    font-size: 1.5rem;

    &.opened {
      color: $danger;
    }

    &.closed {
      color: $success;
    }
  }
}