// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_modal.scss";
 .modal-header {
   display: flex;
     align-items: flex-start;
     justify-content: space-between;
     padding: 1rem 1rem;
     border: 0;
 }
 .hr {
   display: block;
   width: 6rem;
   border-top: 1px solid rgb(0, 0, 0);
   margin: 1em 0;
   padding: 0;
 }
// DEDICATED STYLES
// --------------------------------------------------