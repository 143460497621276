// DEDICATED STYLES
// --------------------------------------------------

// .Map
.Map {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: rgba($primary, 0.1);

  .Block {
    margin: 0;
  }
  .map {
    min-height: 32rem;
    height: 100%;
  }
}

// .map
.map {
  border-radius: inherit !important;
}

// .MapHeight
.MapHeight-xl {
  .map {
    min-height: 72rem;
  }
}
.MapHeight-lg {
  .map {
    min-height: 48rem;
  }
}
.MapHeight-sm {
  .map {
    min-height: 24rem;
  }
}
