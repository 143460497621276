.MaintenanceStepperContainer {
  & > h4 {
    text-transform: uppercase;
    text-align: center;
    margin: 7rem 0 5rem;
  }

  .StepperGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1rem;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
