// DEDICATED STYLES
// --------------------------------------------------

// .Overlay
.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  padding: $spacer-base;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: $transition-base;
  background: rgba($body-color, $alpha-overlay);
  color: $body-bg;

  .h3 {
    font-weight: $font-weight-normal;
  }
  .Icon {
    position: static;
    margin: 0;
    color: inherit;
    height: $figure-icon-size;
    width: $figure-icon-size;

    svg {
      * {
        box-shadow: $box-shadow-lg;
      }
    }
  }
  & > big {
    font-size: $font-size-base*2;
    font-weight: $font-weight-bold;
  }

  // a#{&}
  @at-root {
    a:hover & {
      background: rgba($body-color, $alpha-base-hover);
    }
  }

  &.no-bg {
    background: none;
    color: inherit;

    // a#{&}
    @at-root {
      a:hover & {
        background: none;
      }
    }
  }
}
