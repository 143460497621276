.BasicModal {
  margin-top: 25vh;
  max-width: 800px;


  .modal-header {
    border: none;
    justify-content: center;

    .modal-title {
      font-size: 2rem;
    }
  }

  .modal-content {
    padding: 2rem;

    .modal-body {
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
  }

  @include media-breakpoint-down(xs) {
    margin: 0;

    .modal-content {
      padding: 2rem 0.5rem;
      border-radius: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .modal-body {
        padding: 0;
        flex-grow: 0;
      }
    }
  }
}