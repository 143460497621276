// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_badge.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .Badge
.Badge {
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    font-size: $font-size-sm;
    padding-top: $badge-padding-y + ($badge-padding-y * 0.2);

    // .badge-pill
    &.badge-pill {
        overflow: hidden;
        border-radius: $border-radius-pill;
    }
}
  
// .BadgeIcon
.BadgeIcon {
    display: inline-flex;
    padding: 0;
    height: $btn-height;
    width: $btn-height;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: inherit;
}

// Colors editorial
@each $color, $value in $theme-colors-editorial {
    .badge-#{$color} {
        @include badge-variant($value);
    }
}

// Colors functionnal
@each $color, $value in $theme-colors-functionnal {
    .badge-#{$color} {
        @include badge-variant($value);
    }
}