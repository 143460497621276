// DEDICATED STYLES
// --------------------------------------------------

// .TagFilters
.TagFilters {
  margin: 0 auto ($spacer-section*2);

  .ButtonPill {
    height: min-content;
  }
}

