// DEDICATED STYLES
// --------------------------------------------------

// .Section
.Section {
  width: 100%;
  position: relative;
  overflow: visible;
  padding-top: $spacer-base;
  padding-bottom: $spacer-base;

  @include media-breakpoint-up(md) {
    padding-top: $spacer-section;
    padding-bottom: $spacer-section;
  }
  &:not([class*="bg-"]) + .Section:not([class*="bg-"]) {
    padding-top: 0;
  }
  @include clearfix();

  [class*="container"] > *:last-child {
    margin-bottom: 0;
  }

  @at-root {
    .HasBgFadedAlternance & {
      &:not(:first-child) {
        background: $body-bg;

        &:nth-child(odd) {
          background-color: $body-bg-alt;
        }
      }
    }
  }
  &.bg-alt {
    background: $body-bg-alt;
  }

  &[class*="oblique"] {
    .Container {
      min-height: 16rem;
    }
  }
}

.login-container {
  height: 80vh;
}
