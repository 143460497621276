// DEDICATED STYLES
// --------------------------------------------------

// .Label
.Label {
    &:not(.form-check-label):not(.form-radio-label) {
        margin: 0;
        padding: 0 $input-padding-x;
    
        &.Label--sm {
            padding: 0 $input-padding-x-sm;
        }
        &.Label--lg {
            padding: 0 $input-padding-x-lg;
        }
        &:not(.form-check-label) {
            text-transform: uppercase;
            color: $text-muted;
            font-size: $font-size-sm;
        }
    }
}

// .LabelButton
.LabelButton {
    display: inline-flex;
    align-items: center;
    font-weight: normal;
    flex-wrap: nowrap;
    justify-content: center;

    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: $btn-white-space;
    vertical-align: middle;
    font-size: $input-font-size;
    min-height: $input-height;
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;

    position: relative;

    @include transition($btn-transition);

    min-width: 4rem;
    max-width: 100%;

    &.Label--sm {
        font-size: $input-font-size-sm;
        min-height: $input-height-sm;
    }
    &.Label--lg {
        font-size: $input-font-size-lg;
        min-height: $input-height-lg;
    }
    input {
        appearance: none;
        position: static;
        margin: 0;
        outline: none;
        width: 0;
        height: initial;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            border-radius: $input-border-radius;
            cursor: pointer;

            @include transition($btn-transition);

            background: transparent;
        }
        &:checked {
            & + * {
                //color: color-yiq($primary);
                position: relative;
            }
            &:after {
                background: rgba($primary, 0.25) !important;
            } 
        }
        &:disabled {
            &:after {
                cursor: default;
            }
            & + div + span {
                opacity: 0.2;
            }
        }
    }
    &:not(.NoBorder) {
        input {
            &:after {
                box-shadow: $input-box-shadow;
            }
        }
    }
    &:hover {
        input {
            &:after {
                background: rgba($primary, 0.1);
            }
            &:disabled {
                &:after {
                    background: transparent !important;
                } 
            } 
        }
    }
}