// DEDICATED STYLES
// --------------------------------------------------

// .DefinitionList
.DefinitionList {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.
  
  dt {
    border-top: $table-border-width solid $table-border-color;
    padding: $table-cell-padding;
  }
  dd {
    margin: 0;
    padding: 0 $table-cell-padding $table-cell-padding;
  }
}
