// PROJECT UTILITIES
// --------------------------------------------------

// .ComponentsView
.ComponentsView {
  .Container {
    & > .h1 {
      color: $info;
    }
    & > .h2 {
      color: $info;
      font-size: $h1-font-size;
      margin-bottom: $grid-gutter-width;
    }
  }
}