// PROJECT UTILITIES
// --------------------------------------------------

// .Dashboard
.Dashboard {
    background-color: $body-bg-alt;

    .h2 {
        color: $primary;
        margin-bottom: $spacer-section;
        font-size: $h4-font-size;
    }
    .date, .address, .deadline, .link {
        font-size: $font-size-sm;
        margin: 0;
    }
    .deadline, .address, .bill, .dateFull{
        color: $text-muted;
    }
    .time, .Price {
        font-size: $font-size-lg;
    }
    .link {
        text-decoration: underline;
    }
    .dateTime {
        line-height: 1.2em;
    }

    .time {
    display: block;
    }
    .name {
        font-weight: $font-weight-bold;
    }
    .amount {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-base/2;

        .Badge {
            margin-left: $spacer-base/2;
            text-transform: none;
            transform: translateY(0.1rem);
        }
    }
    .timeRange {
        display: flex;
        align-items: center;

        .Icon {
            margin: 0 0.4rem;
        }
    }

    // .Card
    .Card {
        .card-header {
            text-transform: none;

            .card-title {
                font-weight: $font-weight-bold;
                color: $dark-grey;
                margin-bottom: $spacer-base/2;
            }
        }
        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .card-text {
                margin: 0;
            }
        }
    }

    // .Alert
    .Alert {
        box-shadow: $box-shadow-lg;

        @each $color, $value in $theme-colors {
            &.alert-#{$color} {
                border-color: $value;
            }
        }
        .alert-heading {
            font-size: $font-size-base;
        }
        & > .Row {
            & > [class*="col"] {
                &:first-child {
                    align-self: stretch;
                }
                .Figure {
                    margin-top: -$alert-padding-y !important;
                    margin-bottom: -$alert-padding-y !important;
                    margin-left: -$alert-padding-x !important;
                    width: auto;
                    flex: 1 1 auto !important;
                    border-top-left-radius: $border-radius-md;
                    border-bottom-left-radius: $border-radius-md;
                }
            }
        }
    }
}

// .DashboardGrid
.DashboardGrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    gap: map_get($grid-gutter-widths,md);
    grid-template-areas:
      "one two"
      "one three"
      "one four";

    .One { grid-area: one; }
    .Two { grid-area: two; }
    .Three { grid-area: three; }
    .Four { grid-area: four; }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr 1fr;
        grid-template-areas:
      "one one"
      "two two"
      "three four";
    }

    @include media-breakpoint-down(xs) {
        grid-template-columns: 1fr;
        grid-template-areas:
      "one"
      "two"
      "three"
      "four";
    }

    & > * {
        display: flex;
        flex-direction: column;

        .Card {
            flex: 1 0 auto;
        }
    }
}


