.priceMarker {
  .marker {
    height: 100%;
    width: 100%;
    background-color: darken($primary, 8);
    color: $white;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: $border-radius-lg;

    &:after {
      content: '';
      background-color: darken($primary, 8);
      height: 10px;
      width: 10px;
      position: absolute;
      bottom: -5px;
      transform: rotateZ(45deg);
      left: calc(50% - 5px);
    }

    &.selected {
      background-color: lighten($primary, 5);

      &:after {
        background-color: lighten($primary, 5);
      }
    }
  }
}

.simpleMarker {
  filter: brightness(0) saturate(100%) invert(17%) sepia(49%) saturate(3215%) hue-rotate(274deg) brightness(85%) contrast(97%);

  &.selected {
    filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(6999%) hue-rotate(283deg) brightness(88%) contrast(84%);

    &:after {
      filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(6999%) hue-rotate(283deg) brightness(88%) contrast(84%);
    }
  }
}