// DEDICATED STYLES
// --------------------------------------------------
// @mixin menu-mobile
@mixin menu-mobile {
    position: absolute;
    z-index: $zindex-fixed;
    width: 90vw;
    max-width: 32rem;
    left: -100%;
    top: $header-height;
    bottom: 0;
    transition: $transition-base;
    @at-root {
        .MenuOpen & {
            left: 0;
        }
    }
    .Nav {
        margin: 0;
        .nav-link {
            .Icon {
                margin-right: 1rem;
            }
        }
    }
}

// @mixin menu-desktop
@mixin menu-desktop {
    width: 24rem;
    .MenuContainer {
        z-index: 0;
        position: sticky;
        top: $header-height;
    }
    .Nav {
        margin: 0;
        .nav-link {
            flex-direction: column;
            align-items: center;
            .Icon {
                height: 2.4rem;
                width: 2.4rem
            }
        }
    }
}

// .Menu
.Menu {
    background: $body-bg;
    box-shadow: $box-shadow-lg;
    .MenuContainer {
        z-index: 0;
        max-height: 100%;
        overflow: hidden;
        overflow-y: scroll;
    }
    .MenuContainerEditorial {
        flex: 0 0 auto;
        background: $body-bg;
        border-bottom: solid $hr-border-width $hr-border-color;
    }
    .MenuContainerDashboard {
        flex: 1 0 auto;
    }
    .MenuNav {
        flex-direction: column;
        align-items: stretch;
        margin: 0;
    }
    .MenuSocial {
        padding: $spacer-base;
    }
    .h2 {
        margin: $spacer-base 0;
        font-weight: $font-weight-normal;
        font-size: 1.6rem;
        text-transform: uppercase;
    }
    .Nav {
        margin: 0;
        .nav-link {
            padding: $spacer-base;
            position: relative;
            transition: $transition-base;
            border-radius: 0 $border-radius-md $border-radius-md 0;
            @include hover-focus {
                background: rgba($primary, 0.1);
            }
            &.active {
                background: $primary !important;
                color: color-yiq($primary);
            }
        }
    }
    .MenuContainerDashboard {
        .Nav {
            .nav-link:not(.active):not(:hover):not(:active) {
                color: $secondary;
            }
        }
    }
    @include media-breakpoint-down(md) {
        @include menu-mobile();
    }
    @include media-breakpoint-up(lg) {
        @include menu-desktop();
    }
}