.Kpi {
  text-transform: uppercase;
  color: $primary;
  text-align: center;
  margin: 5rem 2rem;

  .Figure {
    justify-content: center;
    margin-bottom: 2rem;
  }

  .Title, .Description {
    font-size: 3rem;
    line-height: 3rem;
  }
}
