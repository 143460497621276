.MaintenanceChargeCard {
  border: $primary 1px solid!important;
  text-align: center;
  color: $primary;
  padding: 2rem 1rem;

  .card-header {
    .card-text, .card-title {
      margin: 0;
      font-size: 3rem;
    }

    @include media-breakpoint-down(md) {
      .card-text, .card-title {
        font-size: 2.5rem;
      }
    }


    @include media-breakpoint-down(sm) {
      .card-text, .card-title {
        font-size: 2rem;
      }
    }
  }
}
