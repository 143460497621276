// DEDICATED STYLES
// --------------------------------------------------

// .Icon
.Icon {
  display: inline-block;
  height: $icon-size;
  width: $icon-size;
  vertical-align: middle;
  margin: auto;
  box-sizing: content-box;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    * {
      fill: currentColor;
    }
  }

  &.rounded {
    position: relative;
    padding: 0.5rem;

    .round {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      * {
        fill: currentColor;
      }
    }
    svg:not(.round) {
      position: relative;

      * {
        fill: $body-bg;
      }
    }
  }
}

// Sizes
@each $size, $value in $icon-sizes {
  .IconSize--#{$size} {
    height: ($value * 1rem);
    width: ($value * 1rem);
  }
}