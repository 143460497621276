$stepper-base-unit: 4rem;

.ticket-stepper-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: $stepper-base-unit;
  margin: 10rem 0;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .title, .subtitle {
    margin-bottom: 0;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 0 !important;
    padding-top: 3rem;

    .title, .subtitle {
      display: none;
    }
  }

  .stepper-icon {
    position: absolute;
    z-index: 1;
    right: -$stepper-base-unit / 2;
    top: 0;
    height: $stepper-base-unit;
    width: $stepper-base-unit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    border-radius: $stepper-base-unit;
    font-weight: 1000;
    background-color: $black;
    color: $white;
    border: 1px solid $black;

    @include media-breakpoint-down(lg) {
      top: -$stepper-base-unit / 2;
      left: -$stepper-base-unit / 2;
    }
  }

  &.selected .stepper-icon {
    background-color: $white;
    color: $black;
  }

  &.validated {
    cursor: pointer;

    .stepper-icon {
      background-color: $white;
      color: $success;
      border-color: $success;
    }
  }
}