// DEDICATED STYLES
// --------------------------------------------------

// .CardResume
.CardResume {
  .card-body {
    padding-top: $card-spacer-y;
    padding-bottom: $card-spacer-y;

    & > .Row {
      & > [class*="col"] {
        flex: 1 1 auto !important;
        min-width: 30%;
        position: relative;

        &:not(:first-child) {
          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 10%;
            bottom: 10%;
            border-left: solid $border-width $body-bg;
          }
        }
      }
    }
  }
  .card-title {
    flex-grow: 0 !important;
    color: $primary;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
}
