// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_input-group.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .InputGroup
.InputGroup {
  position: relative;

  .Input:first-child {
    border-top-left-radius: $input-border-radius !important;
    border-bottom-left-radius: $input-border-radius !important;
  }
  .Input:last-child {
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
  }
  .input-group-append {
      position: absolute;
      z-index: 4;
      right: 0;
      height: 100%;

      .input-group-text {
        background: transparent;
        border: transparent;
        line-height: 1em;
        font-size: 2rem;
        padding: 1rem
      }

      & + .Input {
        border-radius: $input-border-radius !important;
        padding-right: 3rem;
      }
  }
}