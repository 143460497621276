.card-ticket {
  cursor: pointer;
  padding: 2rem 1rem;
  box-shadow: $card-ticket-around-shadow!important;
  text-align: center;
  margin: 1rem 0;

  &:hover {
    box-shadow: $card-ticket-around-shadow-lg!important;
  }

  &.selected {
    box-shadow: $card-ticket-around-shadow-inset!important;
    background-color: $gray-200;
  }

  .Figure {
    .Icon {
      color: black;
    }
  }

  .card-body {
    margin: auto;
    padding: 0;
    flex-grow: 0;

    .card-text {
      font-size: 1.3rem;
    }
  }
}