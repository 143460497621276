// DEDICATED STYLES
// --------------------------------------------------

// html, body, #root
html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

// .Global
.Global {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  &.MenuOpen {
    overflow: hidden;
  }
}

// .GlobalContainer
.GlobalContainer {
  min-height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    "Header Header"
    "Menu Main"
    "Footer Footer";
}

// .Header
.Header {
  grid-area: Header;
}

// .Header
.Footer {
  grid-area: Footer;
}

// .Menu
.Menu {
  grid-area: Menu;
}

// .Main
.Main {
  grid-area: Main;
  position: relative;
  display: flex;
  flex: 1 1 100%;
  z-index: 1;

  @include media-breakpoint-up(sm) {
    flex-direction: column;
  }

  .app-content {
    flex: 1;
  }
}
