// IMPORT BOOTSTRAP MIXINS
// --------------------------------------------------
@import "~bootstrap/scss/_mixins.scss";

// DEFAULT MIXINS
// --------------------------------------------------

// @mixin clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// @mixin parent
@mixin parent {
  @each $selector in & {
    $l: length($selector);

    @if ($l == 1) {
      @error "Used parent mixin on a top-level selector";
    }
    @else {
      $parent: nth($selector,1);
      @for $i from 2 to $l {
        $parent: append($parent,nth($selector,$i));
      }
      @at-root #{$parent} {
        @content;
      }
    }
  }
}

// @mixin make-order - Make order incremental value from 1 to $number
@mixin make-order($number) {
  @for $i from 1 through $number {
    &:nth-child(#{$i}) {
      order: $i;
    }
  }
}

// @mixin make-container-width - For each breakpoint, define equivalent container width
@mixin make-container-width($max-width: $container-max-widths) {
  @each $breakpoint in map-keys($max-width) {
    @include media-breakpoint-up($breakpoint) {
      max-width: map-get($max-width, $breakpoint);
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the padding of the container in a media query
@mixin make-container-padding(
  $gutters: $grid-gutter-widths,
  $additional-paddings: $container-additional-paddings
) {
  @each $breakpoint in map-keys($additional-paddings) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      $additional-padding: map-get($additional-paddings, $breakpoint);
      padding-right: ($gutter / 2) + $additional-padding;
      padding-left: ($gutter / 2) + $additional-padding;
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the horizontal padding of the grid in a media query
@mixin make-gutters-size-x($gutter-ratio: 1, $gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-base: map-get($gutters, $breakpoint);
      $gutter-base: map-get($gutters, $breakpoint);
      $gutter: $gutter-base * $gutter-ratio;
      padding-left: ($gutter / 2);
      padding-right: ($gutter / 2);
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the vertical padding of the grid in a media query
@mixin make-gutters-size-y($gutter-ratio: 1, $gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-base: map-get($gutters, $breakpoint);
      $gutter: $gutter-base * $gutter-ratio;
      padding-top: ($gutter / 2);
      padding-bottom: ($gutter / 2);
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the horizontal margin of the grid in a media query
@mixin make-row-size($gutter-ratio: 1, $gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-base: map-get($gutters, $breakpoint);
      $gutter: $gutter-base * $gutter-ratio;
      margin-left: -($gutter / 2);
      margin-right: -($gutter / 2);
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the vertical margin of the grid in a media query
@mixin make-grid-size($gutter-ratio: 1, $gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-base: map-get($gutters, $breakpoint);
      $gutter: $gutter-base * $gutter-ratio;
      margin-top: -($gutter / 2);
      margin-bottom: -($gutter / 2);
      @at-root {
        .container > & {
          @if $gutter > $gutter-base {
            &:first-child {
              margin-top: $gutter - $gutter-base;
            }
            &:last-child {
              margin-bottom: $gutter - $gutter-base;
            }
          }
        }
      }
    }
  }
}

// @mixin make-container-padding - Has overlay
@mixin has-overlay($color: $body-bg, $bg: $brand-info, $alpha: $alpha-base) {
  $active-bg: darken($bg, $variant-button-hover);
  background: rgba($bg, $alpha);
  color: $color;
  @at-root {
    a & {
      transition: $transition-base;
      color: $color;
    }
  }
  @at-root {
    a:hover & {
      background: rgba($active-bg, $alpha);
    }
  }
}

// BOOTSTRAP MIXINS OVERRIDE
// --------------------------------------------------

// @mixin button-variant-patch - Include focus constance on text color
@mixin button-variant-patch($color) {
  &:focus {
    color: $color;
  }
}

// @mixin button-box-shadow-variant
@mixin button-box-shadow-variant($color) {
  box-shadow: 0 1rem 1.25rem -0.5rem rgba(darken($color,10%), 0.4) !important;
}

// @mixin bg-variant-patch - Include color YIG to obtain white text without .text-white className
@mixin bg-variant-text($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: color-yiq($color);

    @if color-yiq-test($color) == "light" {
      a:not([class*="btn"]):not([class*="badge"]:not([class*="card"])) {
        color: $body-bg;

        @include hover() {
          color: darken($body-bg, $emphasized-link-hover-darken-percentage);
        }
      }
    }
  }
}

// @mixin oblique-variant - Include color YIG to obtain white text without .text-white className
@mixin oblique-variant($parent, $color, $ignore-warning: false) {
  #{$parent}[class*="oblique-"] {
    &:before, &:after {
      background-color: $color;
    }
  }
}

// @mixin has-fade-bottom - Insert a gradient mask from bottom
@mixin has-fade-bottom($color) {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2rem;
    transition: all linear 0.05s;
    @include gradient-y(transparentize($color, 1), transparentize($color, 0));
  }
}

// PROJECT MIXINS
// --------------------------------------------------

/* LINKS */
@mixin link-variant($color, $hover-color: $color) {
  color: $color;

  &:hover, &:focus {
    color: $hover-color !important;
  }
}

/* TAGS */
@mixin button-selected-variant($color) {
  $text-color: color-yiq($color);

  &.selected {
    color: $text-color !important;
    background-color: $color !important;
  }
  &.selected:hover {
    border-color: darken($color,$variant-button-hover) !important;
    background-color: darken($color,$variant-button-hover) !important;
  }
}

// @mixin object-fit
@mixin object-fit($fit: "cover") {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  &:after{
    content: "";
    display: block;
    height: 0;
    width: 100%;
  }
  img, .video {
    position: absolute;
    object-fit: $fit;
    width: 100%;
    height: 100%;
  }
}
