// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_navbar.scss";

// MIXINS
// --------------------------------------------------

// @mixin NavbarMenu
@mixin NavbarMenuMobile {

// .Navbar
.Navbar {
    color: $tertiary;
    
    .Container {
        flex-wrap: wrap;
    }
}

}

// @mixin NavbarMenu
@mixin NavbarMenuDesktop {

// .Navbars
.Navbar {
    .Container {
        flex-wrap: nowrap;
    }
    .MenuSocial {
        margin-left: $spacer;
    }
    .MenuUser {
        .Button {
            margin-left: 0 !important;
        }
    }
}

}

// DEDICATED STYLES
// --------------------------------------------------

// .Navbar
.Navbar {
    box-shadow: $box-shadow-lg;
    flex-wrap: nowrap;

    .Container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }
    .Nav {
        margin: 0;

        .nav-item {
            display: flex;
            align-items: center;
        }
    }
}

// .NavbarToggler
.NavbarToggler {
    color: currentColor !important;
    border: none;
    margin: 0;
    padding: 0.8rem;

    .Icon {
        font-size: 2.4rem;

        svg {
            position: relative;
            .path {
                position: relative;
                transition: $transition-base;
            }
            .path:nth-child(1) {
                transform-origin: left center;
            }
            .path:nth-child(3) {
                transform-origin: left center;
            }
            .path:nth-child(2) {
                transform-origin: center;
            }
        }
    }
}

// .NavbarBrand
.NavbarBrand {
    display: flex;
    align-items: center;

    .Logo {
        display: block;
        width: auto;

        @include media-breakpoint-down(sm) {
            height: 3rem;
        }
        @include media-breakpoint-up(md) {
            height: 5rem;
        }
    }
}

// .NavbarLogo
.NavbarLogo {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

// .NavbarMenu
.NavbarMenu {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

// RESPONSIVE
@include media-breakpoint-down(sm) {
    @include NavbarMenuMobile;
}
@include media-breakpoint-up(md) {
    @include NavbarMenuDesktop;
}

