// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// TRANSITION
@import "~bootstrap/scss/transitions";

// UTILITIES
@import "~bootstrap/scss/utilities";

// CLOSE
@import "~bootstrap/scss/close";

// PROJECT UTILITIES
// --------------------------------------------------

// .Block
.Block {
  padding: $card-spacer-y $card-spacer-x;
  text-align: center;

  @at-root {
    span#{&} {
      display: inline-block;

      & + & {
        margin-left: $nav-divider-margin-y;
      }
    }
  }
}

// [class*="text-"]
@each $color, $value in $theme-colors-functionnal {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

// [class*="bg-"]
@each $color, $value in $theme-colors {
  @include bg-variant-text(".bg-#{$color}", $value, true);
}

// [class*="bg-gradient"]
@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-variant-text(".bg-gradient-#{$color}", $value, true);
  }
}

// [class*="oblique-"]
[class*="oblique-"] {
  position: relative;
  background: transparent !important;

  &:before, &:after{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: -10%;
    right: -10%;
    background: $body-bg;
  }
  &:before {
    top: 0;
    bottom: 40%;
  }
  &:after {
    top: 40%;
    bottom: 0;
  }
}
@each $color, $value in $theme-colors {
  @include oblique-variant(".bg-#{$color}", $value, true);
}
.oblique-direction-up {
  &:before {
    transform: rotate(-2deg);
  }
}
.oblique-direction-down {
  &:before {
    transform: rotate(2deg);
  }
}
.oblique-position-up {
  &:before {
    top: -8rem;
  }
}
.oblique-position-down {
  &:before {
    top: 8rem;
  }
}


// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

// Responsive
.desktop {
  @include media-breakpoint-down(md){
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(lg){
    display: none;
  }
}

// [class*="Rounded--"]
.Rounded--sm {
  border-radius: $border-radius-sm !important;
}
.Rounded--md {
  border-radius: $border-radius-md !important;
}
.Rounded--lg {
  border-radius: $border-radius-lg !important;
}

// Text
.text-underline {
  text-decoration: underline;
}

// .Toggle
.Toggle {
  display: flex;
  align-items: baseline;
  cursor: pointer;

  & > * {
    flex: 1 1 auto;
  }
  & > .Icon:last-child {
    margin: 0;
    flex: 0 0 auto;
  }
}