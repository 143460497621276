.card-ticket-category {
  cursor: pointer;
  padding: 0.5rem;
  width: 140px;
  min-width: 140px;
  height: 200px;
  margin: 2rem 1rem!important;
  box-shadow: $card-ticket-shadow!important;

  &:hover {
    box-shadow: $card-ticket-shadow-lg!important;
  }

  &.selected {
    box-shadow: $card-ticket-shadow-inset!important;
    background-color: $gray-200;
  }

  .Figure {
    .Icon {
      color: black;
    }
  }

  .card-body {
    margin-top: -2rem;
    padding: 0;
    display: flex;

    .card-text {
      margin: auto!important;
      overflow: hidden;
      text-align: center;
      font-size: 1.3rem;
    }
  }
}