.leaflet-popup {
  margin-bottom: 3rem;
  margin-left: 10rem;
}
.leaflet-popup-content-wrapper {
  border-radius: 0;
  background: $body-bg;
  text-align: unset;
  padding: 0;
}
.leaflet-popup-content {
  margin: 0;
  width: 36rem !important;
  max-width: 100vw;
}
.leaflet-popup-tip-container {
  width: 0;
}

.leaflet-popup-content p {
  margin: auto;
  display:inline-block;
}

// .MapComplexCard
.MapComplexCard {
  text-align: left;
  min-height: 12rem;

  .card-body {
    padding: $spacer-base !important;
  }
  .Figure {
    min-height: 8rem;
    height: 100%;
    @include object-fit(cover);
  }
  .Button {
    position: absolute;
    top: 100%;
    margin-top: -2rem;
    right: 2rem;
    border-radius: 0;
  }
}