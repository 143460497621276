.card-ticket-contact {
  cursor: pointer;
  padding: 0.5rem 0.5rem 1rem;
  box-shadow: $card-ticket-around-shadow!important;
  text-align: center;
  min-height: 280px;
  margin: 1rem 0;

  &:hover {
    box-shadow: $card-ticket-around-shadow-lg!important;
  }

  &.selected {
    box-shadow: $card-ticket-around-shadow-inset!important;
    background-color: $gray-200;
  }

  .Figure {
    width: 60px;
    margin: auto;
    .Icon {
      color: black;
    }
  }

  .card-header {
    .card-title {
      text-transform: none;
      font-size: 2.2rem;
    }
  }

  .card-body {
    padding: 0;
    flex-grow: 0;

    .card-text {
      font-size: 1.2rem;
    }
  }

  .card-footer {
    display: flex;
    flex-direction: column;

    .card-text {
      width: 100%;
    }
  }
}
