.WaterGraphCard {
  .card-body {
    z-index: 1;

    .WaterGraph.recharts-responsive-container {
      min-height: 350px;

      .recharts-bar .recharts-label-list .recharts-text {
        fill: white;
      }
    }
  }

  .Icon {
    position: absolute;
    bottom: 0;
    left: -4%;
    width: auto;
    min-width: 108%;
    height: auto;
    color: $primarylight;
    opacity: 0.8;
    z-index: 0;
  }
}
