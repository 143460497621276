.FileCard {
  border: 1px solid $secondary;
  border-radius: $border-radius-md;
  padding: 0.4rem 1rem;
  margin: 1rem 0.5rem 1rem 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &.Hover:hover {
    opacity: 0.3;
    cursor: pointer;
  }
}
