// DEDICATED STYLES
// --------------------------------------------------

// .CardContact
.CardContact {
  .card-header, .card-body {
    padding-left: $card-spacer-y;
    padding-right: $card-spacer-y;
  }
  .card-header {
    text-transform: none;
  }
  .name, .function, .hours {
    line-height: 1.2em;
    margin: 0;
  }
  .name {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }
  .function {
    font-size: $font-size-sm;
  }
  .hours {
    font-size: $font-size-sm;
  }
  .coordinates {
    font-size: $font-size-md;

    & > li {
      clear: both;
      margin-top: $spacer-base; 
    }
    .Icon {
      float: left;
      margin-right: $spacer-base/2;
    }
    .Text {
      display: block;
      overflow: hidden;
      line-height: 1.2em;
    }
    .Label {
      text-transform: none !important;
      display: block;
      margin: 0;
      padding: 0;
      line-height: 1em;
    }
  }
}
