.AdvancedFilters {
  position: absolute;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: $transition-base;
  overflow: auto !important;
  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
  .card-header {
    padding-top: 0;
  }
  .buttonItem {
    flex: 1 0 40%;
    margin-right: 5%;
    margin-bottom: 0.8rem;

    @include media-breakpoint-down(xs) {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
}