// DEDICATED STYLES
// --------------------------------------------------
// .Figure
.Figure {
    width: 100%;
    position: relative;
    margin: 0;
    flex: 0 0 auto !important;
    display: flex;
    overflow: hidden;
    &.auto {
        img {
            width: auto;
            max-width: 100%;
        }
    }
    .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        height: $figure-icon-size;
        width: $figure-icon-size;
        margin-top: -$figure-icon-size/2;
        margin-left: -$figure-icon-size/2;
        color: $body-bg;
        svg {
            * {
                box-shadow: $box-shadow-lg;
            }
        }
    }
    // [class*="ratio--"]
    &[class*="ratio--"] {
        @include object-fit(cover);
    }
    @each $ratio,
    $value in $ratios {
        &.ratio--#{$ratio} {
            &:after {
                padding-top: 100% / $value;
            }
        }
    }
    // [class*="occupation--"]
    &[class*="occupation--"] {
        justify-content: center;
        align-items: center;
    }
    @each $occupation,
    $value in $occupations {
        &.occupation--#{$occupation} {
            img {
                transform: scale($value);
            }
        }
    }
    // .cap
    &.cap {
        @include object-fit(fill);
        &:after {
            padding-top: 50vh;
        }
    }
    // .circle
    &.circle {
        max-width: 15rem;
        img {
            border-radius: 20rem;
        }
    }
    // .Badge
    &>.Badge {
        position: absolute;
        z-index: 1;
        top: $spacer-base;
        right: $spacer-base;
    }
    &.fill>img {
        object-fit: fill;
    }
}