// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_buttons.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .Button
.Button {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  flex-wrap: nowrap;
  justify-content: center;

  &, &:hover, &:focus {
    box-shadow: none !important;
  }

  .Icon,
  .Text {
    display: inline-block;
  }
  .Icon + .Text,
  .Text + .Icon {
    margin-left: 0.5em;
  }
  .Icon {
    flex: 0 0 auto;
  }
  .Text {
    white-space: normal;
    flex: 1 1 100%;
    text-align: left;
  }
  &.HasShadow {
    @each $color, $value in $theme-colors {
      &.btn-#{$color} {
        @include button-box-shadow-variant($value);
      }
    }
  }
  &.flex-column {
    .Text {
      text-align: center;
      line-height: 1.2em;
    }
    .Figure {
      margin-bottom:$btn-padding-y-lg;
      justify-content: center;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}

// .ButtonPill
.ButtonPill {
  overflow: hidden;
  border-radius: $border-radius-pill;
}

// .ButtonIcon
.ButtonIcon {
  padding: 0;
  text-align: center;
  justify-content: center;
  border-width: 0.2rem;
  position: relative;

  min-height: $btn-height;
  min-width: $btn-height;

  &.btn-xs {
    min-height: $btn-height-xs;
    min-width: $btn-height-xs;
  }
  &.btn-sm {
    min-height: $btn-height-sm;
    min-width: $btn-height-sm;
  }
  &.btn-lg {
    min-height: $btn-height-lg;
    min-width: $btn-height-lg;
  }
  [class*="IconSize--"] {
    margin: 1rem;
  }
  .Badge {
    position: absolute !important;
    top: 0.4rem !important;
    right: 0.4rem !important;
    padding: 0;
    min-width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .ButtonPhone
.ButtonPhone {
  width: auto !important;
  padding-left: 0.75rem;
  
  .Icon {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      border: solid 0.2rem currentColor;
      border-right-color: transparent;
      border-radius: $border-radius-pill;
      top: -0.8rem;
      left: -0.8rem;
      right: -0.8rem;
      bottom: -0.8rem;
    }
  }
  .Text {
    position: relative;
  }
}

// Colors editorial
@each $color, $value in $theme-colors-editorial {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

// Colors outline editorial
@each $color, $value in $theme-colors-editorial {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Colors selected
@each $color, $value in $theme-colors {
  .btn-#{$color}, .btn-outline-#{$color} {
    &.selected {
      @include button-selected-variant($value);
    }
  }
}

// Colors selected editorial
@each $color, $value in $theme-colors-editorial {
  .btn-#{$color}, .btn-outline-#{$color} {
    &.selected {
      @include button-selected-variant($value);
    }
  }
}

// Make a button look and behave like a link
.btn-link {
  text-decoration: underline;
  color: $primary;

  @include hover() {
    color: darken($primary, $variant-button-hover);
  }
}