// DEDICATED STYLES
// --------------------------------------------------

// .Mosaic
.Mosaic {
    .Row.grid > .Info {
        padding-top: 0;
        padding-bottom: 0;
        font-size: $font-size-sm;
    }
}

// .Map
.Map {
    & + .card-body {
        padding-top: $card-spacer-y/2;
    }
}

// .PropertyLabel
.PropertyLabel {
    font-weight: $font-weight-light;
    font-size: $h2-font-size;

    strong {
        font-weight: bold;
    }
}

// .HousingLocation
.HousingLocation {
    font-size: $font-size-lg;
    line-height: 1em;
    margin-bottom: $spacer-base;
}

// .HousingPrice
.HousingPrice {
    margin-bottom: $spacer-base !important;

    .Price {
        font-size: $font-size-base*2;
        vertical-align: baseline;
        white-space: nowrap;

        small {
            font-size: $font-size-base;
        }
    }
    .PriceNet {
        font-style: normal;
        color: $text-muted;
        line-height: 1em;
    }
}

// .CallCenter
.CallCenter {
    display: flex;
    align-items: center;

    .Badge {
        flex: 0 0 auto;
        margin-right: 1rem;
        margin-left: -0.5rem;
        height: 5rem;
        width: 5rem;
        font-size: 2.5rem;
        transform: translateY(0.4rem);
        
    }
    .Text {
        flex: 1 1 100%;
        color: $text-muted;
        font-size: $font-size-sm;

        strong {
            display: block;
            font-size: 3rem;
            line-height: 0.8em;
            color: $body-color;
            white-space: nowrap;
        }
    }
}

// .CardHousingProperties
.CardHousingProperties {
    text-align: center;

    .Row {
        overflow: hidden;
    }
    [class*="col"] {
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -0.1rem;
            top: (map-get($grid-gutter-widths, xl))/2;
            bottom: (map-get($grid-gutter-widths, xl))/2;
            border-left: solid 0.1rem $secondary;
        }
    }
    strong {
        font-size: $h3-font-size;
    }
}