// IMPORT BOOTSTRAP
// --------------------------------------------------

// DEDICATED STYLES
// --------------------------------------------------

// .DropdownSelect
.DropdownSelect {
    .DropdownSelectToggle {
        @extend .form-control;
        @extend select.form-control;

        display: flex;
        align-items: center;
        text-align: left;

        &, &:focus, &:active {
            &:not(.disabled) {
                background-color: $input-bg;
                border-color: $input-border-color;
                color: inherit;
            }
        }
        &:focus {
            &:not(.disabled) {
                background-color: $input-focus-bg;
                border-color: $input-focus-border-color;
                color: inherit;
            }
        }

        span {
            display: block;
            flex: 1 1 100%;
            overflow: auto;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &.form-control-sm {
            @extend .form-control-sm;
        }
        &.form-control-lg {
            @extend .form-control-lg;
            font-size: $font-size-base;
        }

        &.NoBorder {
            &, &:focus, &:active {
                border-width: 0;
            }
            &:focus {
                &:not(.disabled) {
                    box-shadow: 0 0 0 $input-focus-width rgba($primary, .25);
                }
            }
        }

        @each $state, $data in $form-validation-states {
            $color: map-get($data, color);

            &.is-#{$state} {
                &:not(.disabled) {
                    border-color: $color;
                }
                &:focus:not(.disabled) {
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
            }
        }
    }
    .DropdownSelectMenu {
        min-width: 100%;
    }
}
