// DEDICATED STYLES
// --------------------------------------------------

// .CardDocument
.CardDocument {
  .card-body {
    padding: $card-spacer-y;
    padding-top: 0;
  }
  .card-title {
    font-size: $font-size-base;
    margin: 0;
  }
  .size {
    font-size: $font-size-sm;
  }
  .Figure {
    margin: 0 !important;
  }
  .Overlay {
    justify-content: flex-end;
    align-items: flex-start;

    .Icon {
      height: 2.4rem;
      width: 2.4rem;
      color: $primary;
    }
  }
}
