// DEDICATED STYLES
// --------------------------------------------------

// .CardHousingsArticle
.CardHousingsArticle {
  .card-body {
    .Button {
      min-width: 16rem;
    }
  }
}
