.EditorialStepper {
  .EditorialStep {
    .card-header {
      .Number {
        display: flex;
        margin-right: 1rem;
        font-size: 2.5rem;
        font-weight: bold;
        background-color: $primary;
        color: $white;
        min-width: 40px;
        min-height: 40px;
        border-radius: 5rem;
        justify-content: center;
        align-items: center;
      }

      .Title {
        font-weight: bold;
        user-select: none;
      }

      .Icon {
        color: $primary;
        font-size: 2rem;
      }
    }

    .card-body {
      position: relative;
      padding-top: 0;

      .Hr {
        position: absolute;
        border-top: 0;
        border-left: 1px solid $gray-300;
        width: 1px;
        height: auto;
        margin: 0 0 0 11.5px;
        bottom: 36px;
        top: 0;
        z-index: 0;
      }

      .EditorialStepItem {
        margin: 2rem 0;
        user-select: none;
        cursor: pointer;
        font-weight: bold;
        display: flex;

        &:last-child {
          margin-bottom: 0;
        }

        .Check {
          z-index: 1;
          margin-right: 2rem;
          min-width: 25px;
          min-height: 25px;
          max-height: 25px;
          max-width: 25px;
          font-size: 1.5rem;
          font-weight: bold;
          color: $white;
          border: 3px solid $gray-300;
          border-radius: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;

          &.success {
            background-color: $success;
            border-color: lighten($success, 20);
          }
        }

        .Text {
          &.success {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
