// DEDICATED STYLES
// --------------------------------------------------

// .JoinUsCard
.JoinUsCard {
  .card-body, .card-header {
    padding: $grid-gutter-width;
  }
  .card-header + .card-body {
    padding-top: 0;
  }
  .card-title {
    font-size: 2rem;
  }
}