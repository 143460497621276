// DEFAULT VARIABLES
// --------------------------------------------------

// Fonts body
$font-family-base: "Lato", sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-size-base: 1.6rem;
$font-size-md: 1.4rem;
$font-size-sm: 1.2rem;
$font-size-lg: 2rem;

// Fonts headings
$headings-font-family: $font-family-base;
$headings-font-weight: $font-weight-bold;

// Colors
$primary:       #6a1e76;
$secondary:     #b6b6b6;
$tertiary:      #301834;
$info:          #91559C;
$light:         #f7f6f8;
$primarylight:  #f1f4fe;
$dark:          #1d1d1d;
$dark-grey:     #4d4d4d;
$toast:         #A12DB1;
$success:       #7CCA86;

$theme-colors: (
        "primary":      $primary,
        "secondary":    $secondary,
        "tertiary":     $tertiary,
        "info":         $info,
        "light":        $light,
        "dark":         $dark,
        "primarylight": $primarylight,
);

$body-bg: #ffffff;
$body-bg-alt: $light;
$body-color: $dark;

$text-muted: #757575;

// Functionnal colors
$alert:       #c02424;

$theme-colors-functionnal: (
        "alert":            $alert
);

// Editorial colors
$theme-colors-editorial: (
        //"primary":              $primary,
        //"secondary":            $secondary,
        //"info":                 $info,
        //"light":                $light,
        //"dark":                 $dark,

        "SlateGray":            #708090, // Gris
        "Tan":                  #D2B48C, // Beige
        "Coral":                #FF7F50, // Orange
        "Firebrick":            #B22222, // Rouge
        "Plum":                 #DDA0DD, // Mauve
        "Purple":               #800080, // Violet
        "SkyBlue":              #87CEEB, // Bleu clair
        "RoyalBlue":            #4169E1, // Bleu foncé
        "YellowGreen":          #9ACD32, // Vert clair
        "DarkOliveGreen":       #556B2F, // Vert foncé
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $body-color;
$yiq-text-light: $body-bg;

// Global ratio
$global-percent: 62.5%;
$global-font-size: 16px;
$global-ratio: strip-unit($global-font-size * $global-percent / 100);

// Grid columns gutters ratios
$gutter-ratio-xs: 1/8;
$gutter-ratio-sm: 1/4;
$gutter-ratio-md: 1/2;
$gutter-ratio-lg: 1/1;
$gutter-ratio-xl: 3/2;

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 40px;
$grid-gutter-widths: (
        xs: $grid-gutter-width * 1/4,
        sm: $grid-gutter-width * 1/4,
        md: $grid-gutter-width * 1/2,
        lg: $grid-gutter-width * 3/4,
        xl: $grid-gutter-width
);

// Grid containers additional paddings
$container-additional-padding-base: 20px;
$container-additional-paddings: (
        xs: $container-additional-padding-base * 1/4,
        sm: $container-additional-padding-base * 1/4,
        md: $container-additional-padding-base * 1/2,
        lg: $container-additional-padding-base * 2/3,
        xl: $container-additional-padding-base
);

// Grid containers
$container-max-widths: (
        xs: 100%,
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1200px + (map-get($container-additional-paddings, xl) * 2)
);

// Grid breakpoints
// ATTENTION : si changement, changer les variables dans style.js
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: map-get($container-max-widths, xl)
);

// Figure Ratios
$ratios: (
        '2-3': 2/3,
        '3-4': 3/4,
        '1-1': 1/1,
        '4-3': 4/3,
        '3-2': 3/2,
        '16-9': 16/9
);

// Figure Occupation
$occupations: (
        "50": 0.5,
        "60": 0.6,
        "70": 0.7,
        "80": 0.8,
        "90": 0.9,
);

// Sizing
$header-height: 6rem;
$banner-min-height: 40rem;

// Spacer
$spacer-base: 1.6rem;
$spacer-section: 3.6rem;

// Border
$border: 0.1rem;
$border-lg: 0.2rem;

// Border-radius
$border-radius-none:          0;
$border-radius-lg:            1.6rem;
$border-radius-md:            1rem;
$border-radius-sm:            0.4rem;
$border-radius-pill:          10rem;

$border-radius:               $border-radius-none;

// Box shadow
$box-shadow-lg: 0 1rem 2rem -1rem rgba($dark, 0.2);
$box-shadow-card: 0 0.5rem 1rem -0.5rem rgba($dark, .2);

$card-ticket-shadow: 0 0.1rem 0.2rem -0.1rem rgba($dark, 0.2);
$card-ticket-shadow-lg: 0 0.2rem 0.4rem -0.2rem rgba($dark, 0.3);
$card-ticket-shadow-inset: inset 0 -0.2rem 0.4rem rgba($dark, 0.2);

$card-ticket-around-shadow: 0 0 0.4rem rgba($dark, 0.2);
$card-ticket-around-shadow-lg: 0 0 0.8rem rgba($dark, 0.3);
$card-ticket-around-shadow-inset: inset 0 0 0.4rem rgba($dark, 0.2);

// Variants
$variant-button-hover: 15%;

// Alpha
$alpha-base: 0.5;
$alpha-base-hover: 0.25;
$alpha-overlay: 0.8;

// Transition
$transition-base: all 0.2s ease-in-out;

// Icon
$icon-size: 1em;
$icon-sizes: (
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6
);

// Badge
$badge-padding-x: 0.8rem;
$badge-padding-y: 0.4rem;
$badge-pill-padding-x: $badge-padding-x + $badge-padding-y;
$badge-border-radius: $border-radius;

// Input
$input-border-color: lighten($secondary,20%);
$input-box-shadow: $box-shadow-card;

$input-border-radius: 0.6rem;
$input-border-radius-sm: 0.6rem;
$input-border-radius-lg: 0.6rem;

$input-font-size: $font-size-base;
$input-font-size-sm: 1.4rem;
$input-font-size-lg: 1.8rem;

$input-btn-padding-y:         0.8rem;
$input-btn-padding-x:         0.8rem;
$input-btn-padding-y-sm:      0.4rem;
$input-btn-padding-x-sm:      0.8rem;
$input-btn-padding-y-lg:      1.2rem;
$input-btn-padding-x-lg:      0.8rem;

$input-border-width: 0.1rem;

$input-color: $body-color;
$input-placeholder-color: $text-muted;

$form-check-input-gutter:               1.8rem;
$form-check-input-margin-y:             0.6rem;

$custom-control-indicator-size: 1.6rem;

// Button
$btn-border-radius: $border-radius-sm;
$btn-border-radius-lg: $border-radius-sm;
$btn-border-radius-sm: $border-radius-sm;

$btn-padding-y:         0.8rem;
$btn-padding-x:         1.6rem;
$btn-padding-y-sm:      0.4rem;
$btn-padding-x-sm:      0.8rem;
$btn-padding-y-lg:      1.2rem;
$btn-padding-x-lg:      2.4rem;

// Card
$card-spacer-y: 2.4rem;
$card-spacer-x: 3.6rem;
$card-border-radius: $border-radius;

// Figure
$figure-icon-size: 5rem;

// Nav
$nav-tabs-height: 5rem;

// Alert
$alert-padding-y:                   $card-spacer-y;
$alert-padding-x:                   $card-spacer-x;
$alert-margin-bottom:               $grid-gutter-width;
$alert-border-radius:               $border-radius-md;

// IMPORT BOOTSTRAP VARIABLES
// --------------------------------------------------
@import "~bootstrap/scss/variables";


// BOOTSTRAP VARIABLES COMPLEMENT
// --------------------------------------------------

// Buttons
$btn-height: ($btn-font-size * $btn-line-height) + ($btn-padding-y * 2);
$btn-height-xs: 1.6rem;
$btn-height-sm: ($btn-font-size-sm * $btn-line-height-sm) + ($btn-padding-y-sm * 2);
$btn-height-lg: ($btn-font-size-lg * $btn-line-height-lg) + ($btn-padding-y-lg * 2);
