// DEDICATED STYLES
// --------------------------------------------------

// .Heading
.Heading {
  margin-bottom: $grid-gutter-width;

  .Row {
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
    [class*="col"]:only-child {
      flex-basis: 100%;
    }
  }

  .UpTitle {
    margin: 0;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
}

@each $color, $value in $theme-colors {
  $inverse: color-yiq($value);

  .bg-#{$color} {
    .Heading {
      .UpTitle {
        @if color-yiq-test($inverse) == "dark" {
          color: $inverse;
          opacity: $alpha-base;
        }
        @else {
          color: $primary;
        }
      }
    }
  }
}
