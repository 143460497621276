// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_nav.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .Nav
.Nav {
  margin-bottom: $grid-gutter-width;

  .nav-link {
    display: flex;
    align-items: center;
  }
  &:not(.flex-column) {
    & > .nav-item:not(:first-child) {
      & > .Button, & > button {
        margin-left: $nav-divider-margin-y;
      }
    }
  }
  &.flex-column {
    .nav-link {
      display: flex;

      .Text {
        flex: 1 1 100%;
      }
    }
  }
}

// .Nav.nav-pills
.Nav.nav-pills {
  
}

// .Nav.nav-tabs
.Nav.nav-tabs {
  &:not(.HasNet) {
    margin-bottom: 0;
    border-bottom-width: 0;
  
    .nav-item {
      margin: 0 (-$border-radius-md);
      max-width: 50%;
      min-width: 15rem;
  
      @include media-breakpoint-up(md) {
        min-width: 20rem;
      }
  
      .nav-link {
        display: flex;
        position: relative;
        border-width: 0;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        padding: 0 $spacer-base;
        text-transform: uppercase;
        font-size: $font-size-lg;
        height: $nav-tabs-height;
        justify-content: center;
  
        &:not(.active) {
          background: rgba($primary, $alpha-base);
          color: color-yiq($primary);
          cursor: pointer;
        }
        &.active {
          z-index: 1;
        }
      }
    }
  }
  &.HasNet {
    border: none;

    .nav-item {
      max-width: 50%;
      min-width: 15rem;

      @include media-breakpoint-up(md) {
        min-width: 20rem;
      }
      border-bottom: solid ($border-width*2) currentColor;

      .nav-link {
        border: none;
        position: relative;
        background: transparent;
        color: $text-muted;
        justify-content: center;
        height: $nav-tabs-height;
        cursor: pointer;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 0;
          background: currentColor;
          transition: $transition-base;
          border-radius: 0.2rem;
        }
        &.active {
          color: currentColor;
          
          &:after {
            height: 0.6rem;
            margin-top: -0.3rem;
          }
        }
      }
    }
  }
}
