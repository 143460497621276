// DEDICATED STYLES
// --------------------------------------------------

// .MenuSocial
.MenuSocial {
    flex: 0 0 auto;
    margin-left: $spacer-base;

    .nav-item {
        .Button {
            height: 3.3rem;
            width: 3.3rem;
            min-height: 0;
            min-width: 0;
        }
    }
}

