#PrivacyPolicy {
  table {
    width: 100%;

    thead {
      background-color: $gray-200;
    }

    &, th, td {
      padding: 1rem;
      border: 1px solid black;
    }
  }
}
