// THEME FONTS SOURCES */
// --------------------------------------------------

// Lato (300, 300i, 400, 400i, 700)
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;600&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// TYPE
@import "~bootstrap/scss/type";

// PROJECT TYPES
// --------------------------------------------------

// body
body {
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
}

// strong
strong {
  font-weight: $font-weight-bold;
}

// .hn
h1, .h1, h2, .h2, h3, .h3 {
  font-weight: $headings-font-weight;
}
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: $font-weight-bold;
}
h1, .h1 {
  font-size: $h1-font-size;
}
h2, .h2 {
  font-size: $h2-font-size;
}
h3, .h3 {
  font-size: $h3-font-size;
}
h4, .h4 {
  font-size: $h4-font-size;
}
h5, .h5 {
  font-size: $h5-font-size;
}
h6, .h6 {
  font-size: $h6-font-size;
}

// .lead
.lead {
  font-size: 2rem;
  font-weight: 600;
}

// .hr
.Hr {
  margin: $grid-gutter-width 0;
}

// p
p {
  margin-bottom: $spacer-base;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}