// IMPORT VENDOR
// --------------------------------------------------

// Swiper
@import "~swiper/swiper.scss";
@import "~swiper/components/pagination/pagination.scss";
@import "~swiper/components/navigation/navigation.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .Carousel
.Carousel {
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0px;
  z-index: 1;
}

.swiper-button-next {
  color: $primary;
}

.swiper-button-prev {
  color: $primary;
}

.swiper-pagination {
  .swiper-pagination-bullet-active {
    background-color: $primary;
  }
}