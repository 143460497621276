// DEDICATED STYLES
// --------------------------------------------------

// .MenuUser
.MenuUser {
    flex: 0 0 auto;

    @include media-breakpoint-down(md){
        margin-right: 1rem!important;
    }

    .UserText {
        cursor: pointer;
        margin-right: 0.8rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media-breakpoint-down(xs){
            max-width: 14rem;
        }
        @include media-breakpoint-between(lg,lg){
            max-width: 9rem;
        }
    }
}

