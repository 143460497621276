.MaintenanceStepperCard {
  height: 100%;
  cursor: pointer;

  .Figure {
    padding: 2rem 3rem;
    .Img {
      margin: auto;
    }
  }

  .card-body {
    padding: 1rem;
    text-align: center;

    .card-title {
      margin: 0;
      font-size: 1.5rem;
    }

    .card-text {
      font-size: 1.3rem;
    }
  }
}
