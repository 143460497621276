.offer-detail {
  color: $dark-grey;

  .city {
    line-height: 1;
    font-size: 1rem;
  }

  .title {
    font-size: 2.5rem;
    font-weight: $font-weight-bolder;
  }

  .description {
    h2 {
      margin-top: $spacer-section;
    }
    h3 {
      margin-top: $spacer-section;
    }
    h4, h5, h6 {
      margin-top: $spacer-base;
    }
    ul {
      padding-left: 3rem;
      margin-bottom: $spacer-base;

      li {
        list-style: none;
        position: relative;
        margin: 1rem 0;
        
        &::before {
          position: absolute;
          content: "•";
          font-size: 2rem;
          color: $primary;
          display: inline-block;
          width: 1rem;
          margin-left: -2rem;
          top: -0.4rem;
        }
      }
    }
  }
}