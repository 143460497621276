// DEDICATED STYLES
// --------------------------------------------------

// .Banner
.Banner {
  position: relative;
  margin: 0;

  .Figure {
    @include object-fit(cover);
    min-height: $banner-min-height;
  }
  .Container {
    position: relative;
    z-index: 1;
    margin-top: -$banner-min-height / 3;
  }

  &.InsideContent {
    min-height: $banner-min-height;
    color: color-yiq($body-color);

    padding-top: $spacer-base;
    padding-bottom: $spacer-base;

    @include media-breakpoint-up(md) {
      padding-top: $spacer-section;
      padding-bottom: $spacer-section;
    }

    .Container {
      margin-top: 0;
    }
    .Figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      min-height: $banner-min-height;
    }
  }
  &.Centered {
    display: flex;
    align-items: stretch;

    .Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &.no-fit-cover {
    img {
      object-fit: unset !important;
    }
  }
}
