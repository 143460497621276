.MaintenanceStepCard {
  cursor: pointer;

  .card-body {
    padding: 3rem 1.5rem;

    .card-title {
      text-align: center;
      font-size: 1.5rem;
    }

    .card-text {
      font-size: 1.3rem;
      text-align: center;

      ul {
        padding-left: 2rem;
        text-align: left;
      }
    }
  }
}
