// DEDICATED STYLES
// --------------------------------------------------

// .Input
.Input {
  margin-bottom: 1.5rem;
  
  &.NoBorder {
      border-width: 0;
  }
}

.upload-link {
  position: relative;
  overflow: hidden;
}
.input-file {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  opacity: 0;
  z-index: 2;

  &:hover + div + .input-file-label .label {
    text-decoration: underline;
  }
}
.input-file-label {
  pointer-events: none;
}
.input-file-name {
  color: $text-muted;
  font-size: $font-size-sm;
}
.text-description{
  min-height: 100px; 
}