// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import "~bootstrap/scss/_card.scss";

// DEDICATED STYLES
// --------------------------------------------------

// .Card
.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  color: $body-color;

  &.selected {
    .Figure {
      img {
        transform: scale(1.1);
      }
    }
    .Overlay {
      background: rgba($body-color, $alpha-base-hover);
    }
  }

  /*& + .Card {
    margin-top: map_get($grid-gutter-widths,md);
  }*/
  & + .Card,
  & + p {
    margin-top: $spacer-base;
  }
  @at-root {
    .grid > [class*="col-"] > & {
      height: 100%;
    }
  }

  & > .Row {
    & > [class*="col"] {
      display: flex;
      flex-direction: column;
    }
  }

  // .card-header, .card-footer, .card-body
  &:not(.no-fade) {
    @at-root {
      a#{&} {
        .card-header,
        .card-footer,
        .card-body {
          transition: $transition-base;
          opacity: $alpha-base;
        }
      }
      a:hover#{&} {
        .card-header,
        .card-footer,
        .card-body {
          opacity: 1;
        }
      }
    }
  }
  .card-header,
  .card-body,
  .card-footer {
    .List:last-child, .h3:last-child, p:last-child {
      margin-bottom: 0;
    }
    .Figure:not(:only-child) {
      margin-bottom: $card-spacer-y;
    }
    &:not(:last-child) {
      padding-bottom: $card-spacer-y/2;
    }
  }
  .card-header,
  .card-footer {
    flex: 0 0 auto;

    .Row {
      align-items: center;
    }
  }
  .card-header {
    text-transform: uppercase;
    background: transparent;
    border: none;

    & > *:first-child {
      margin-top: 0;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    & + .card-body {
      padding-top: 0;
    }
  }
  .card-footer {
    display: flex;
    background: transparent;
    border: none;
  }
  &.text-center {
    .card-footer {
      justify-content: center;
    }
  }
  .card-body {
    flex: 1 0 auto;

    & + .card-footer {
      padding-top: 0;
    }
  }

  &:not(.no-zoom) {
    .Figure {
      img {
        transition: $transition-base;
        transform-origin: center;

        @at-root {
          a:hover#{&} {
            transform: scale(1.1);
          }
        }
      }
      @each $occupation, $value in $occupations {
        &.occupation--#{$occupation} {
          $scale: $value*1.1;
          img {
            // a#{&}
            @at-root {
              a:hover#{&} {
                transform: scale($scale)
              }
            }
          }
        }
      }
    }
  }

  // :not(.no-border)
  &:not(.no-border) {
    box-shadow: $box-shadow-lg;
    border-width: 0;
    overflow: hidden;

    @at-root {
      a:hover#{&} {
        background: rgba-to-rgb(rgba($primary, 0.05));
      }
    }
    & > .Row {
      margin: 0;

      & > [class*="col"] {
        padding: 0;

        .Figure {
          flex-grow: 1 !important;
          @include object-fit(cover);
        }
      }
      .card-header,
      .card-footer,
      .card-body {
        @include make-gutters-size-x(2);
      }
      // &.gutters-none {
      //   .card-header,
      //   .card-footer,
      //   .card-body {
      //     @include make-gutters-size-x(0);
      //   }
      // }
      &.gutters-xs {
        .card-header,
        .card-footer,
        .card-body {
          @include make-gutters-size-x($gutter-ratio-xs*2);
        }
      }
      &.gutters-sm {
        .card-header,
        .card-footer,
        .card-body {
          @include make-gutters-size-x($gutter-ratio-sm*2);
        }
      }
      &.gutters-md {
        .card-header,
        .card-footer,
        .card-body {
          @include make-gutters-size-x($gutter-ratio-md*2);
        }
      }
      &.gutters-lg {
        .card-header,
        .card-footer,
        .card-body {
          @include make-gutters-size-x($gutter-ratio-lg*2);
        }
      }
      &.gutters-xl {
        .card-header,
        .card-footer,
        .card-body {
          @include make-gutters-size-x($gutter-ratio-xl*2);
        }
      }
    }
  }

  // .no-border
  &.no-border {
    &,
    .card-header,
    .card-body,
    .card-footer {
      border-width: 0;
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }
    .card-img, .Figure {
      transition: $transition-base;
      box-shadow: none;
    }
    @at-root {
      a:hover#{&} {
        background: transparent !important;

        .Figure {
          box-shadow: $box-shadow-lg;

          &.auto {
           box-shadow: none;
          }
        }
      }
    }
    &.Rounded--sm {
      .Figure {
        border-radius: $border-radius-sm !important;
      }
    }
    &.Rounded--md {
      .Figure {
        border-radius: $border-radius-md !important;
      }
    }
    &.Rounded--lg {
      .Figure {
        border-radius: $border-radius-lg !important;
      }
    }
  }

   // .no-shadow
   &.no-shadow {
     box-shadow: none;
   }

  // Types
  .card-title {
    font-size: 1.8rem;
  }

  // .Badge
  & > .Badge {
    position: absolute;
    z-index: 1;
    top: $card-spacer-y;
    right: $card-spacer-x;
  }

  // .List
  .List {
    & > .card-text {
      &:not(:first-child) {
        border-top: solid $border-width $table-border-color;
        padding-top: $spacer-base/2;
      }
      &:not(:last-child) {
        padding-bottom: $spacer-base/2;
      }
    }
  }

  // .More
  .More {
    padding: 0;
    color: $text-muted;

    @include hover {
      text-decoration: none;
    }
    .Icon {
      font-size: 4rem;

      .bar, .circle {
        transition: $transition-base;
      }
    }
    .Text {
      opacity: 0;
      transition: $transition-base;
    }


    @at-root {
      a:hover#{&} {
        color: $primary;

        .Icon {
          .bar {
            transform: scaleX(1);
          }
          .circle {
            transform: scale(0);
          }
        }
        .Text {
          opacity: 1;
        }
      }
    }
  }

  // a#{&}
  @at-root {
    a#{&} {
      text-decoration: none;
      cursor: pointer;
      transition: $transition-base;
    }
  }

  &.disabled {
    cursor: not-allowed;
    @at-root {
      a:hover#{&} {
        background: unset;

        .Text {
          opacity: $alpha-base!important;
        }
      }
    }

    .LoaderContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $secondary;
      opacity: $alpha-base;
      display: flex;
      justify-content: center;
      pointer-events: none;

      .Loader {
        margin: auto;
      }
    }
  }
}

// DEBUG
/*.card-img {
  border: solid 2px lime !important;
}
.card-header {
  border: solid 2px orange !important;
}
.card-body {
  border: solid 2px red !important;
}
.card-footer {
  border: solid 2px magenta !important;
}*/
